import axios from 'axios'

import { AxiosService } from './axios.service'

export class UserService extends AxiosService {
  //  static _instance = new UserService();

  regUser = (params: any) => {
    return this.SendMessageByPost('/public/user/reg?', params)
  }

  regCheckID = (params: any) => {
    return this.SendMessageByPost('/public/user/check_user_id?', { id: params })
  }

  healthCheck = async () => {
    //return {}
    return this.SendMessageByPost('/public/user/user_health_check', {})
  }

  getUserInfo = () => {
    return this.SendMessageByPost('/public/user/get_member?', {})
  }

  doLogin = (id: any, pw: any) => {
    return this.SendMessageByPost('/User/do_login?', { id: id, pw: pw })
  }

  do_read_note = (id: string) => {
    return this.SendMessageByPost('/public/community/usre_do_read_note', { id: id })
  }

  do_read_all_note = () => {
    return this.SendMessageByPost('/public/community/do_read_all_note', {})
  }

  do_del_all_note = () => {
    return this.SendMessageByPost('/public/community/do_del_all_note', {})
  }

  get_user_notices = () => {
    return this.SendMessageByPost('/public/community/user_get_notices', {})
  }

  get_bets = (date: any, page: number) => {
    return this.SendMessageByPost('/public/slot/get_bets', { date: date, page: page })
  }

  get_user_note = async () => {
    return {}
    //return this.SendMessageByPost('/public/community/user_get_note', {})
  }

  get_user_note_list = () => {
    return this.SendMessageByPost('/public/community/user_get_note_list', {})
  }

  do_del_note = (id: string) => {
    return this.SendMessageByPost('/public/community/usre_do_del_note', { id: id })
  }

  get_advertisement = (type: string) => {
    return this.SendMessageByPost('/public/community/user_get_advertisement', {
      type: type,
    })
  }

  get_notice_popup = async () => {
    return { note: [] }
    //return this.SendMessageByPost('/public/community/user_get_notice_popup', {})
  }

  get_help_list = () => {
    return this.SendMessageByPost('/public/community/get_help_list', {})
  }

  user_wirte_help = (title: string, contents: string) => {
    return this.SendMessageByPost('/public/community/user_wirte_help', {
      title: title,
      contents: contents,
    })
  }

  user_del_help = (id: string) => {
    return this.SendMessageByPost('/public/community/user_del_help', { id: id })
  }

  user_chang_to_point = () => {
    return this.SendMessageByPost('/public/user/user_chang_to_point', {})
  }

  user_exchange_to_pass = (pass: any) => {
    return this.SendMessageByPost('/public/user/user_exchange_to_pass', { pass })
  }

  do_help_message_read = (id: any) => {
    return this.SendMessageByPost('/public/community/admin_do_help_message_read', { id })
  }

  user_chang_to_rolling = () => {
    return this.SendMessageByPost('/public/user/user_chang_to_rolling', {})
  }

  regCheckNick = (params: any) => {
    return this.SendMessageByPost('/public/user/check_user_nick', { nick: params })
  }
}

// export const UserMgr = UserService.getInstance(); // do something with the instance...
