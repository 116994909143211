import axios from 'axios'

import { AxiosService } from './axios.service'

export class BalanceService extends AxiosService {
  doDeposit(balance: any) {
    // return  this.SendMessageByPost('/user/get_member')
    return axios.post('assets/data/deposit_list.json', { balance: balance }).then((res) => res.data)
  }

  askToAccount() {
    return axios.post('/public/community/user_ask_account_wirte_help', {}).then((res) => res.data.data)
  }

  applyUserDeposit(balance: any, code: any) {
    return axios
      .post('/public/balance/apply_user_deposit', { balance: balance, code: code })
      .then((res) => res.data.data)
  }

  applyUserWithdraw(balance: any) {
    return axios.post('/balance/apply_user_withdraw', { balance: balance }).then((res) => res.data.data)
  }

  applyUserWithdrawV2(balance: any, code: any) {
    return axios
      .post('/public/balance/apply_user_withdraw', { balance: balance, code: code })
      .then((res) => res.data.data)
  }

  //   doDeposit() {
  //   }

  getDepositList() {
    // return  this.SendMessageByPost('/user/get_member')
    return axios.post('/balance/get_deposit_list', {}).then((res) => res.data.data)
  }

  delDeposit() {
    // return  this.SendMessageByPost('/user/get_member')
    return axios.post('/balance/user_del_deposit', {}).then((res) => res.data.data)
  }

  getWithdrawList() {
    // return  this.SendMessageByPost('/user/get_member')
    return axios.post('/balance/get_withdraw_list', {}).then((res) => res.data.data)
  }

  delWithdraw() {
    // return  this.SendMessageByPost('/user/get_member')
    return axios.post('/balance/user_del_withdraw', {}).then((res) => res.data.data)
  }

  get_balance_deposit_lock() {
    return this.SendMessageByPost('/balance/get_balance_deposit_lock', {})
  }

  get_balance_withdraw_lock() {
    return this.SendMessageByPost('/balance/get_balance_withdraw_lock', {})
  }

  user_balance_to_point = (balance: any) => {
    return this.SendMessageByPost('/public/balance/money_to_point', { balance })
  }

  user_point_to_money = (point: any) => {
    return this.SendMessageByPost('/public/balance/point_to_money', { point })
  }

  user_exchange_to_pass = (pass: any) => {
    return this.SendMessageByPost('/public/user/user_exchange_to_pass', { pass: pass })
  }

  rolingToBalance(rolling: any) {
    return axios.post('/public/balance/user_roll_to_balance', { rolling: rolling }).then((res) => res.data.data)
  }
}
