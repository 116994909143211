import React from 'react'
import { useCookies } from 'react-cookie'
import { confirmAlert } from 'react-confirm-alert' // Import

import { AxiosService } from './service/axios.service'
import { AuthService } from './service/auth.service'

import { Web } from './web/index'

// import logo from "./logo.svg";
import './App.css'

export const isMaster =
  window.location.hostname.indexOf('localhost') >= 0 ||
  window.location.hostname.indexOf('zero') >= 0 ||
  window.location.hostname.indexOf('master') >= 0

console.log(isMaster)

const auth = new AuthService()
// let userService = new UserService();

type CookieType = 'authorization' | 'name'

function App() {
  const [cookie, setCookie, removeCookie] = useCookies<CookieType>()
  // const [session, setSession] = useState(null);
  // const [session, setSession] = useState(null);

  const authenticated: boolean = cookie.authorization != null
  const authorization: any = cookie.authorization != null

  const login = async (id: any, pw: any) => {
    const s = await auth.doLogin(id, pw)

    console.log('login ' + s)

    if (s.status === 'success') {
      if (cookie.authorization != null) {
        removeCookie('authorization', { path: '/' })
      }
      setCookie('authorization', s.data, { path: '/' })
      setCookie('name', id, { path: '/' })
      window.location.href = '/'
    } else {
      confirmAlert({
        title: '로그인 ',
        message: '아이디 또는 비밀번호를 확인해주세요.',
        buttons: [
          {
            label: '확인',
            onClick: () => {
              removeCookie('authorization', { path: '/' })

              //window.location.reload()
            },
          },
        ],
      })
    }
  }

  // const logout = () => setCookie("session", null, { path: "/" });
  const logout = () => {
    confirmAlert({
      title: '로그아웃 ',
      message: '로그아웃 하시겠습니까?',
      buttons: [
        {
          label: '확인',
          onClick: () => {
            removeCookie('authorization', { path: '/' })
          },
        },
      ],
    })
  }

  AxiosService.SetSession(cookie.authorization)

  return (
    <>
      <Web
        tryLogin={login}
        authenticated={authenticated}
        session={authorization.authorization}
        tryLoginOut={() => {
          console.log('tryLoginOut')
          removeCookie('authorization', { path: '/' })
        }}
        SetCookie={(name: string, data: any) => setCookie(name as CookieType, data, { path: '/' })}
        GetCookie={(name: string) => cookie[name as CookieType]}
      ></Web>
      <div id="helpCount" style={{ display: 'none' }}>
        0
      </div>
      {/*authenticated && document.write(`<script>doLiveChat("${cookie.name}")</script>`)*/}
      {/* {
          authenticated && (
            document.write(`<script
            src="https://kr03.tocplus007.com/chatLoader.do?userId=khan3827"
            type="text/javascript"></script>`)
          )
        } */}
    </>
  )
}

export default App
