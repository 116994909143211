import { Component } from 'react'
export class Footer extends Component<object, object> {
  render() {
    return (
      <div>
        <footer className="footer-section">
          <div className="copyright">
            <div className="container">
              <span>ⓒ COPYRIGHT WORLD SLOT 2020 ALL RIGHTS RESERVED.</span>
            </div>
          </div>
        </footer>
        <button className="scroll-top" style={{ display: 'none' }}>
          <i className="fas fa-cloud-upload-alt" aria-hidden="true"></i>
        </button>
        <div className="sn-overlay"></div>
      </div>
    )
  }
}
