import React, { Component } from 'react'
import styled from 'styled-components'

import { makeStyles, withStyles } from '@mui/styles'
import Popup from 'reactjs-popup'
import CloseIcon from '@mui/icons-material/Close'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Paper from '@mui/material/Paper'

import { UserService } from '../../service/user.service'

import { ConvertDate } from '../../utility/help'

import { ShareMenu } from './share_menu'
import { ShareLeft } from './share_left'

import { popupView } from '../../utility/help'

const CustomTableCell = styled(TableCell)`
  color: white;
  padding: 4px;
`

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

function Row(props: { row: any }) {
  const { row } = props
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()

  let ref = ''
  if (row.ref != null) {
    ref = row.ref.contents
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell>
        <CustomTableCell align="center" onClick={() => setOpen(!open)}>
          {row.title}
        </CustomTableCell>
        <CustomTableCell align="center">{ConvertDate(row.row)}</CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div
                style={{
                  height: '400px',
                  overflowY: 'scroll',
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
              </div>
            </Box>
          </Collapse>
        </CustomTableCell>
      </TableRow>
    </React.Fragment>
  )
}

interface Props {
  handleClose: () => any
  handleChange: (viewName: string) => any
}

interface State {
  notices: any
}

export class Notice extends Component<Props, State> {
  userService = new UserService()

  constructor(props: Props) {
    super(props)
    this.state = { notices: [] }
  }

  componentDidMount() {
    this.handleGetNotices()
  }

  handleGetNotices = () => {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data)
      if (data.status === 'success') {
        this.setState({ notices: data.notices })
      }
    })
  }

  render() {
    const notices = this.state.notices
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: 'none',
          border: 'none',
          width: 'none',
        }}
        overlayStyle={{
          overflow: 'scroll',
        }}
      >
        {(close) => (
          <div>
            <div className="modal-app show">
              <button
                className="modal-close-btn modal-close"
                data-dismiss="modal"
                onClick={() => {
                  this.props.handleClose()
                }}
              ></button>
              <a href="/" className="diamond-logo">
                <img className="panel" src="/new/images/panel.png" />
                <img className="dias-lg" src="/new/images/diamond-lg.png" />
                <div className="dias-sm">
                  <img className="diamond-img" src="/new/images/diamond-sm.png" />
                  <img className="glow" src="/new/images/glow.png" />
                </div>
                <img className="diamond" src="/new/images/diamond.png" />
                <img className="slot" src="/new/images/slot.png" />
              </a>

              <ShareMenu handleChange={this.props.handleChange}></ShareMenu>
            </div>

            <div
              className="modal loginModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              aria-modal="true"
              style={{ paddingRight: '17px', display: 'block' }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="row">
                    <ShareLeft handleChange={this.props.handleChange} viewType={popupView.notice}></ShareLeft>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
                      <div className="modal-body">
                        <div className="modal-title">
                          <h6 className="title mb-0">공지사항</h6>
                          <h6 className="sub ml-2 mb-0">NOTICE</h6>
                        </div>

                        <div className="form-container">
                          <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table" style={{ backgroundColor: '#484848' }}>
                              <TableHead>
                                <TableRow>
                                  <CustomTableCell align="center"></CustomTableCell>
                                  <CustomTableCell align="center">제 목</CustomTableCell>
                                  <CustomTableCell align="center">작성일</CustomTableCell>
                                  <CustomTableCell align="center">상태</CustomTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {notices.map((row: any) => (
                                  <Row key={row.name} row={row} />
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-backdrop fade show"></div>
          </div>
        )}
      </Popup>
    )
  }
}
