import React, { Component } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import Popup from 'reactjs-popup'
import CloseIcon from '@mui/icons-material/Close'
import { confirmAlert } from 'react-confirm-alert' // Import
import { UserService } from '../../service/user.service'

interface Props {
  user: any
  activeHelp: boolean
  authenticated: boolean
  tryLogin: (id: any, pw: any) => any
  tryLoginOut: () => any
  handleClose: () => any
}

interface State {
  ID: string
  PW: string
}

export class Login extends Component<Props, State> {
  userService = new UserService()
  constructor(props: Props) {
    super(props)

    this.state = {
      ID: '',
      PW: '',
    }
  }

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: 'none',
          border: 'none',
          width: 'none',
        }}
        overlayStyle={{
          overflow: 'scroll',
        }}
      >
        {(close) => (
          <div>
            <div className="modal-app show">
              <button
                className="modal-close-btn modal-close"
                data-dismiss="modal"
                onClick={() => {
                  this.props.handleClose()
                }}
              ></button>
              <a href="/" className="diamond-logo">
                <img className="panel" src="/new/images/panel.png" />
                <img className="dias-lg" src="/new/images/diamond-lg.png" />
                <div className="dias-sm">
                  <img className="diamond-img" src="/new/images/diamond-sm.png" />
                  <img className="glow" src="/new/images/glow.png" />
                </div>
                <img className="diamond" src="/new/images/diamond.png" />
                <img className="slot" src="/new/images/slot.png" />
              </a>
              <div className="modal-menu-side">
                {/* <button className="deposit-link">
		   <i className="fas fa-coins icon" aria-hidden="true"></i>
		   <span className="text">입금신청</span>
		   </button>
		   <button className="withdraw-link">
		   <i className="fas fa-university icon" aria-hidden="true"></i>
		   <span className="text">출금신청</span>
		   </button>
		   <button className="event-link">
		   <i className="fas fa-gift icon" aria-hidden="true"></i>
		   <span className="text">이벤트</span>
		   </button>
		   <button className="notice-link">
		   <i className="fas fa-bell icon" aria-hidden="true"></i>
		   <span className="text">공지사항</span>
		   </button>
		   <button className="myPage-link">
		   <i className="fas fa-cog icon" aria-hidden="true"></i>
		   <span className="text">마이페이지</span>
		   </button> */}
              </div>
            </div>

            <div
              className="modal loginModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              aria-modal="true"
              style={{ paddingRight: '17px', display: 'block' }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pr-lg-5 px-3">
                      <div className="modal-title">
                        <h6 className="title mb-0">메뉴</h6>
                        <h6 className="sub ml-2 mb-0">MENU</h6>
                      </div>
                      <div className="modal-menu">
                        <button className="active">
                          <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
                          <span>로그인</span>
                          <div className="indicator">
                            <i className="fab fa-ethereum" aria-hidden="true"></i>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
                      <div className="modal-body">
                        <div className="modal-title">
                          <h6 className="title mb-0">로그인</h6>
                          <h6 className="sub ml-2 mb-0">LOGIN</h6>
                        </div>
                        <h1 className="fs-44">Welcome</h1>
                        <p className="mb-md-5 mb-4 text-gray">언제나 노력하는 KHAN 카지노가 되겠습니다.</p>

                        <div className="form-container">
                          <div className="form-group">
                            <div className="labels">
                              <span>아이디</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="text"
                                  placeholder="아이디"
                                  name="mb_login_id"
                                  className="mb-login-id input-popup"
                                  onChange={({ target: { value } }) => this.setState({ ID: value })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="labels">
                              <span>비밀번호</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="password"
                                  placeholder="비밀번호"
                                  name="password"
                                  className="mb-password input-popup"
                                  onChange={({ target: { value } }) => this.setState({ PW: value })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            className="btn-style login-btn"
                            onClick={() => {
                              if (
                                this.state.ID == null ||
                                this.state.ID == '' ||
                                this.state.PW == '' ||
                                this.state.PW == null
                              ) {
                                return
                              }
                              this.props.tryLogin(this.state.ID, this.state.PW)
                            }}
                          >
                            로그인
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-backdrop fade show"></div>
          </div>
        )}
      </Popup>
    )
  }
}
