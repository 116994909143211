import React, { Component } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import Popup from 'reactjs-popup'
import CloseIcon from '@mui/icons-material/Close'
import { confirmAlert } from 'react-confirm-alert' // Import
import { UserService } from '../../service/user.service'

interface Props {
  handleClose: () => any
}

interface State {
  id: string
  nick: string

  pass: string
  pass_check: string
  phone1: string
  phone2: string
  phone3: string
  phone: string

  bankname: string
  banknumber: string
  bankowner: string
  exchange_pw: string
  code: string
}

export class Reg extends Component<Props, State> {
  userService = new UserService()
  constructor(props: Props) {
    super(props)

    this.state = {
      id: '',
      nick: '',

      pass: '',
      pass_check: '',
      phone1: '',
      phone2: '',
      phone3: '',
      phone: '',

      bankname: '',
      banknumber: '',
      bankowner: '',
      exchange_pw: '',
      code: '',
    }
  }

  handleCheck = () => {
    if (this.state.id === undefined || this.state.id.length <= 3 || 20 <= this.state.id.length) {
      confirmAlert({
        title: '회원 가입',
        message: '아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })
      return
    }

    this.userService.regCheckID(this.state.id).then((s: any) => {
      if (s.status === 'success') {
        confirmAlert({
          title: '회원 가입',
          message: '사용 할수있는 아이디 입니다.',
          buttons: [
            {
              label: '확인',
              onClick: () => {},
            },
          ],
        })
        return
      } else {
        confirmAlert({
          title: '회원 가입',
          message: '사용 불가능한 아이디 입니다.',
          buttons: [
            {
              label: '확인',
              onClick: () => {
                this.setState({ id: '' })
              },
            },
          ],
        })
        return
      }
    })
  }

  handleReg = () => {
    if (this.state.id === undefined || this.state.id.length <= 3 || 20 <= this.state.id.length) {
      confirmAlert({
        title: '회원 가입',
        message: '아이디를 입력 또는 3자리 이상 20자리 이하로 작성해주세요',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })
      return
    }

    if (this.state.nick === undefined || this.state.nick.length <= 3 || 20 <= this.state.nick.length) {
      confirmAlert({
        title: '회원 가입',
        message: '닉네임은 2자리 이상 20자리 이하로 작성해주세요',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })
      return
    }

    if (this.state.pass == null || this.state.pass_check == null || this.state.pass !== this.state.pass_check) {
      confirmAlert({
        title: '회원 가입',
        message: '비밀번호를 확인해주세요',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })
      return
    }

    const phone = this.state.phone
    if (phone == null || phone.length <= 10) {
      confirmAlert({
        title: '회원 가입',
        message: '휴대폰 번호를 확인해주세요',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })
      return
    }

    if (this.state.bankname == null) {
      confirmAlert({
        title: '회원 가입',
        message: '은행명을 확인해주세요',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })
      return
    }

    if (this.state.banknumber == null) {
      confirmAlert({
        title: '회원 가입',
        message: '계좌 번호를  확인해주세요',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })

      return
    }

    if (this.state.bankowner == null) {
      confirmAlert({
        title: '회원 가입',
        message: '예금주 이름을 확인해주세요',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })

      return
    }

    this.userService
      .regUser({
        id: this.state.id.trim(),
        pw: this.state.pass,
        pass_check: this.state.pass_check,
        phone: phone,
        bankname: this.state.bankname,
        banknumber: this.state.banknumber,
        bankowner: this.state.bankowner,
        exchange_pw: this.state.bankowner,
        code: this.state.code,
        nick: this.state.nick,
      })
      .then((data: any) => {
        if (data.status === 'alread_have_user') {
          confirmAlert({
            title: '회원 가입',
            message: '생성할수 없는 유저아이디 입니다.',
            buttons: [
              {
                label: '확인',
                onClick: () => {},
              },
            ],
          })
          return
        } else if (data.status === 'cant_find_code') {
          confirmAlert({
            title: '회원 가입',
            message: '회원 가입코드를 확인해주세요.',
            buttons: [
              {
                label: '확인',
                onClick: () => {},
              },
            ],
          })

          return
        } else {
          confirmAlert({
            title: '회원 가입',
            message: '회원 가입에 성공하였습니다.',
            buttons: [
              {
                label: '확인',
                onClick: () => {
                  window.location.reload()
                },
              },
            ],
          })

          return
        }
      })
  }

  handleCheckNick = () => {
    if (this.state.nick === undefined || this.state.nick.length < 2 || 20 <= this.state.id.length) {
      confirmAlert({
        title: '회원 가입',
        message: '닉네임 입력 또는 3자리 이상 20자리 이하로 작성해주세요',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })
      return
    }

    this.userService.regCheckNick(this.state.nick).then((s: any) => {
      if (s.status === 'success') {
        confirmAlert({
          title: '회원 가입',
          message: '사용가능한 닉네임 입니다.',
          buttons: [
            {
              label: '확인',
              onClick: () => {},
            },
          ],
        })
        return
      } else {
        confirmAlert({
          title: '회원 가입',
          message: '사용 불가능한 닉네임 입니다.',
          buttons: [
            {
              label: '확인',
              onClick: () => {
                this.setState({ id: '' })
              },
            },
          ],
        })
        return
      }
    })
  }

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: 'none',
          border: 'none',
          width: 'none',
        }}
        overlayStyle={{
          overflow: 'scroll',
        }}
      >
        {(close) => (
          <div>
            <div className="modal-app show">
              <button
                className="modal-close-btn modal-close"
                data-dismiss="modal"
                onClick={() => {
                  this.props.handleClose()
                }}
              ></button>
              <a href="/" className="diamond-logo">
                <img className="panel" src="/new/images/panel.png" />
                <img className="dias-lg" src="/new/images/diamond-lg.png" />
                <div className="dias-sm">
                  <img className="diamond-img" src="/new/images/diamond-sm.png" />
                  <img className="glow" src="/new/images/glow.png" />
                </div>
                <img className="diamond" src="/new/images/diamond.png" />
                <img className="slot" src="/new/images/slot.png" />
              </a>
              <div className="modal-menu-side"></div>
            </div>

            <div
              className="modal loginModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              aria-modal="true"
              style={{ paddingRight: '17px', display: 'block' }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pr-lg-5 px-3">
                      <div className="modal-title">
                        <h6 className="title mb-0">메뉴</h6>
                        <h6 className="sub ml-2 mb-0">MENU</h6>
                      </div>
                      <div className="modal-menu">
                        <button className="active">
                          <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
                          <span>회원가입</span>
                          <div className="indicator">
                            <i className="fab fa-ethereum" aria-hidden="true"></i>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
                      <div className="modal-body">
                        <div className="modal-title">
                          <h6 className="title mb-0">회원가입</h6>
                          <h6 className="sub ml-2 mb-0">SIGN UP</h6>
                        </div>
                        <p className="mb-md-5 mb-4 text-gray">KHAN 카지노에 오신것을 환영합니다.</p>

                        <div className="form-container">
                          <div className="form-group">
                            <div className="labels">
                              <span>회원 아이디</span>
                            </div>

                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="text"
                                  placeholder="영어또는 숫자조합6~12자리입니다."
                                  name=""
                                  className="input-popup"
                                  style={{ width: '70%' }}
                                  value={this.state.id}
                                  onChange={(e: any) => {
                                    this.setState({ id: e.target.value })
                                  }}
                                />

                                <div className="modal-footer" style={{ margin: '0px' }}>
                                  <button
                                    type="submit"
                                    className="btn-style login-btn"
                                    style={{ width: '30%' }}
                                    onClick={() => {
                                      this.handleCheck()
                                    }}
                                  >
                                    중복확인
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="labels">
                              <span>닉네임</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="text"
                                  name=""
                                  className="input-popup"
                                  style={{ width: '70%' }}
                                  placeholder="닉네임은 2이상 사용하시길 바람니다."
                                  value={this.state.nick}
                                  onChange={(e: any) => {
                                    this.setState({ nick: e.target.value })
                                  }}
                                />

                                <div className="modal-footer" style={{ margin: '0px' }}>
                                  <button
                                    type="submit"
                                    className="btn-style login-btn"
                                    style={{ width: '30%' }}
                                    onClick={() => {
                                      this.handleCheckNick()
                                    }}
                                  >
                                    중복확인
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="labels">
                              <span>비밀번호</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="text"
                                  placeholder="비밀번호는 6~16자리 입니다."
                                  name=""
                                  className="input-popup"
                                  value={this.state.pass}
                                  onChange={(e: any) => {
                                    this.setState({ pass: e.target.value })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="labels">
                              <span>비밀번호 확인</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="text"
                                  placeholder="비밀번호는 6~16자리 입니다."
                                  name=""
                                  className="input-popup"
                                  value={this.state.pass_check}
                                  onChange={(e: any) => {
                                    this.setState({ pass_check: e.target.value })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="labels">
                              <span>전화번호</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="text"
                                  placeholder="'-'없이 숫자만 입력하세요."
                                  name=""
                                  className="input-popup"
                                  value={this.state.phone}
                                  onChange={(e: any) => {
                                    this.setState({ phone: e.target.value })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="labels">
                              <span>은행명</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="text"
                                  placeholder=""
                                  name=""
                                  className="input-popup"
                                  value={this.state.bankname}
                                  onChange={(e: any) => {
                                    this.setState({ bankname: e.target.value })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="labels">
                              <span>계좌번호</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="text"
                                  placeholder=""
                                  name=""
                                  className="input-popup"
                                  value={this.state.banknumber}
                                  onChange={(e: any) => {
                                    this.setState({ banknumber: e.target.value })
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="labels">
                              <span>이름</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="text"
                                  placeholder="이름은 정보와 일치해야합니다."
                                  name=""
                                  className="input-popup"
                                  value={this.state.bankowner}
                                  onChange={(e: any) => {
                                    this.setState({ bankowner: e.target.value })
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="labels">
                              <span>추천코드</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="text"
                                  placeholder=""
                                  name=""
                                  className="input-popup"
                                  value={this.state.code}
                                  onChange={(e: any) => {
                                    this.setState({ code: e.target.value })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn-style login-btn"
                            onClick={() => {
                              this.handleReg()
                            }}
                          >
                            회원가입
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-backdrop fade show"></div>
          </div>
        )}
      </Popup>
    )
  }
}
