import { Component } from 'react'

import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import { UserService } from '../../service/user.service'
import { SlotService } from '../../service/slot.service'

export enum popupView {
  none = 'none',
  deposit = 'deposit',
  withdraw = 'withdraw',
  point = 'point',
  notice = 'notice',
  note = 'note',
  help = 'help',
  user = 'user',

  reg = 'reg',
}

const styles = (theme: any) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    color: '#000',
  },

  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
    display: 'inline',
    padding: '10px',
    fontSize: '16px',
  },
  investing: {
    fontSize: '18px',
  },
})

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

interface Props {
  classes: any
  user: any
  activeHelp: boolean
  authenticated: boolean
  tryLogin: (id: any, pw: any) => any
  tryLoginOut: () => any

  //   session: any;
}

interface State {
  ID: string
  PW: string
  balance: number
  postCount: number
  noteCount: number
  note: any
  isOpen: boolean
  popupStatuses: string
  expanded: string
  notes: any
}
class section extends Component<Props, State> {
  static propTypes: { classes: PropTypes.Validator<object> }
  userService = new UserService()
  slotService = new SlotService()

  constructor(props: Props) {
    super(props)
    this.state = {
      balance: 0,
      ID: '',
      PW: '',
      postCount: 0,
      note: [],
      notes: [],
      isOpen: false,
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: '',
    }
  }

  render() {
    return (
      <section className="banner-section">
        <div className="container">
          <div className="banner-carousel">
            <div className="carousel about-carousel" data-ride="carousel" data-interval="5000" data-pause="false">
              <div className="carousel-inner">
                <div className="carousel-item">
                  <div className="carousel-container">
                    <div className="center text-center">
                      <img className="title-img" src="/new/images/khan.png" style={{ width: '400px' }} />
                      {/* <img className="deco-img" src="/new/images/casino.png"/> */}

                      {/* <h1 className="mb-1"><span style={{fontFamily: 'Great Vibes'}}>KHAN </span><span style={{color:'red'}}>CASINO</span>&nbsp;&nbsp;</h1> */}
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="carousel-container">
                    <div className="center text-center">
                      <img className="title-img" src="/new/images/khan.png" style={{ width: '400px' }} />
                      {/* <img className="deco-img" src="/new/images/casino.png"/> */}
                      {/* <h1 className="mb-1"><span style={{fontFamily: 'Great Vibes'}}>KHAN </span><span style={{color:'red'}}>CASINO</span>&nbsp;&nbsp;</h1> */}
                    </div>
                  </div>
                </div>
                <div className="carousel-item active">
                  <div className="carousel-container">
                    <div className="center text-center">
                      <img className="title-img" src="/new/images/khan.png" style={{ width: '400px' }} />
                      {/* <img className="deco-img" src="/new/images/casino.png"/> */}

                      {/* <h1 className="mb-1"><span style={{fontFamily: 'Great Vibes'}}>KHAN </span><span style={{color:'red'}}>CASINO</span>&nbsp;&nbsp;</h1> */}
                    </div>
                  </div>
                </div>
              </div>
              <ol className="carousel-indicators">
                <li data-target=".about-carousel" data-slide-to="0" className=""></li>
                <li data-target=".about-carousel" data-slide-to="1" className=""></li>
                <li data-target=".about-carousel" data-slide-to="2" className="active"></li>
              </ol>
            </div>
          </div>
          <div className="jackpot-section">
            <div className="container">
              <div className="labels">
                <div className="center">
                  <img className="title-img" src="/new/images/jackpot-title.png" />
                  <img className="deco-img" src="/new/images/gold-bars.png" />
                </div>
              </div>
              <div className="amount">
                <span
                  id="odometer1"
                  className="odometer odometer-theme-minimal odometer-animating-up odometer-animating"
                ></span>
                <span data-text="원" className="text-xs">
                  원
                </span>
              </div>
              <div
                className="carousel information-carousel"
                data-ride="carousel"
                data-interval="5000"
                data-pause="false"
              >
                <div className="carousel-inner">
                  <div className="carousel-item">
                    <span></span>
                  </div>
                  <div className="carousel-item active">
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-background">
            <img className="roulette" src="/new/images/roulette.png" />
            <img className="slot-girl" src="/new/images/slot-girl.png" />
            <div className="glow-right">
              <img src="/new/images/glow-right.png" />
            </div>
            <img className="chimney-girl" src="/new/images/chimney-girl.png" />
            <img className="slot-boy" src="/new/images/slot-boy.png" />
          </div>
        </div>
      </section>
    )
  }
}

section.propTypes = {
  classes: PropTypes.object.isRequired,
}

export const Section = withStyles(styles, { withTheme: true })(section)
export default Section
