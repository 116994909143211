import React, { Component } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import Popup from 'reactjs-popup'
import CloseIcon from '@mui/icons-material/Close'
import { confirmAlert } from 'react-confirm-alert' // Import

import { BalanceService } from '../../service/balance.service'

import { ConverMoeny, popupView } from '../../utility/help'

interface Props {
  viewType: string
  handleChange: (viewName: string) => any
}

interface State {
  balance: number
  point: number
}

export class ShareLeft extends Component<Props, State> {
  render() {
    return (
      <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pr-lg-5 px-3">
        <div className="modal-title">
          <h6 className="title mb-0">메뉴</h6>
          <h6 className="sub ml-2 mb-0">MENU</h6>
        </div>
        <div className="modal-menu">
          <button
            className={this.props.viewType === popupView.deposit ? 'active' : ''}
            onClick={() => {
              this.props.handleChange(popupView.deposit)
            }}
          >
            <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
            <span>입금신청</span>
            <div className="indicator">
              <i className="fab fa-ethereum" aria-hidden="true"></i>
            </div>
          </button>
          <button
            className={this.props.viewType === popupView.withdraw ? 'active' : ''}
            onClick={() => {
              this.props.handleChange(popupView.withdraw)
            }}
          >
            <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
            <span>출금신청</span>
            <div className="indicator">
              <i className="fab fa-ethereum" aria-hidden="true"></i>
            </div>
          </button>
          <button
            className={this.props.viewType === popupView.bet ? 'active' : ''}
            onClick={() => {
              this.props.handleChange(popupView.bet)
            }}
          >
            <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
            <span>배팅내역</span>
            <div className="indicator">
              <i className="fab fa-ethereum" aria-hidden="true"></i>
            </div>
          </button>
          <button
            className={this.props.viewType === popupView.comp ? 'active' : ''}
            onClick={() => {
              this.props.handleChange(popupView.comp)
            }}
          >
            <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
            <span>콤프</span>
            <div className="indicator">
              <i className="fab fa-ethereum" aria-hidden="true"></i>
            </div>
          </button>

          <button
            className={this.props.viewType === popupView.help ? 'active' : ''}
            onClick={() => {
              this.props.handleChange(popupView.help)
            }}
          >
            <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
            <span>고객센터</span>
            <div className="indicator">
              <i className="fab fa-ethereum" aria-hidden="true"></i>
            </div>
          </button>
          <button
            className={this.props.viewType === popupView.user ? 'active' : ''}
            onClick={() => {
              this.props.handleChange(popupView.user)
            }}
          >
            <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
            <span>마이페이지</span>
            <div className="indicator">
              <i className="fab fa-ethereum" aria-hidden="true"></i>
            </div>
          </button>
          <button
            className={this.props.viewType === popupView.notice ? 'active' : ''}
            onClick={() => {
              this.props.handleChange(popupView.notice)
            }}
          >
            <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
            <span>공지사항</span>
            <div className="indicator">
              <i className="fab fa-ethereum" aria-hidden="true"></i>
            </div>
          </button>
        </div>
      </div>
    )
  }
}
