import { Component } from 'react'

import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { withStyles } from '@mui/styles'

import PropTypes from 'prop-types'
import Timer from 'react-compound-timer'
import { ConverMoeny, GetTimeStemp } from '../../utility/help'

import { UserService } from '../../service/user.service'
import { MiniService } from '../../service/mini.service'

import { BalanceService } from '../../service/balance.service'

// import { ConverStatus, ConverBuySell, ConverMoeny } from "../utility/help";

// const CustomCardContent = styled(Typography)``;

// const classes = useStyles();

interface Props {
  classes: any
  authenticated: boolean

  session: any
  user: any

  tryLogin: (id: any, pw: any) => any
  tryLoginOut: () => any
}

interface State {
  start: number
  end: number
  deposits: any
  withdraws: any
  notices: any
  gameVIew: string
  gameDate: string

  balance: number
  history: any
  maxCount: number

  marketId: any
  betId: any
  minis: any
  seconds: number
  startImmediately: any

  betOutcomeId: string
  betOutcomeName: string
  betOutcomeOdds: number
}

class power extends Component<Props, State> {
  divElement: any
  static propTypes: { classes: PropTypes.Validator<object> }
  // fxService = new FxService();
  // userService = new UserService();
  miniService = new MiniService()
  balanceService = new BalanceService()
  userService = new UserService()

  constructor(props: Props) {
    super(props)
    this.state = {
      start: 0,
      end: 0,
      minis: null,
      maxCount: 1,
      gameDate: '',
      seconds: 0,
      startImmediately: false,
      balance: 0,

      betId: '',

      // notices : [],
      deposits: [],
      withdraws: [],
      history: [],
      notices: {},
      gameVIew: '',
      marketId: '',
      betOutcomeId: '',
      betOutcomeName: '',

      betOutcomeOdds: 1,
      // start : (GetTimeStemp() / 1000) % 100000000,
      // end : ((GetTimeStemp() / 1000) % 100000000)+ 1,
      // gameVIew : 'slot'
    }

    setInterval(() => {
      this.setState({
        start: (GetTimeStemp() / 1000) % 100000000,
        end: ((GetTimeStemp() / 1000) % 100000000) + 1,
      })
    }, 2000)

    // this.balanceService.get_balance_deposit_lock().then((s) => {
    //   if (s.status === "success") {
    //      this.setState({ deposits: s.deposits.slice(0, 6) });
    //   }
    // });

    this.userService.get_user_notices().then((data: any) => {
      if (data.status === 'success') {
        this.setState({ notices: data.notices })
      }
    })

    // this.balanceService.get_balance_deposit_lock().then((s) => {
    //   if (s.status === "success") {
    //     this.setState({ deposits: s.deposits });
    //   }
    // });

    // this.balanceService.get_balance_withdraw_lock().then((s) => {
    //   if (s.status === "success") {
    //     this.setState({ withdraws: s.withdraws });
    //   }
    // });
  }

  componentDidMount() {
    if (this.divElement != null) {
      this.setState({})
    }

    this.moveBalance()
    this.updateGame()
    this.updateMiniHistroy(1)
  }

  moveBalance = () => {
    this.miniService.OnlyWithdraw().then((s: any) => {
      if (s.status === 'success' && s.balance > 0) {
        window.location.reload()

        // let now = s.now;
        // this.setState({ minis: s.minis[0] });

        // if (s.minis.length > 0) {
        //   let gameDate = s.minis[0].gamedate;
        //   if (this.state.gameDate !== gameDate) {
        //     let tick =
        //       new Date(gameDate).getTime() - new Date(now).getTime() - 40000;
        //     this.setState({ seconds: tick, startImmediately: true });

        //     setTimeout(() => {
        //       // this.updateGame();
        //       this.updateMiniHistroy(1);
        //     }, tick + 105000);
        //   }
        // }
      }
    })
  }

  selBet = (_betId: string, _betOutcomeId: string, _betOutcomeOdds: number, _betName: string) => {
    this.setState({
      betId: _betId,
      betOutcomeId: _betOutcomeId,
      betOutcomeOdds: _betOutcomeOdds,
      betOutcomeName: _betName,
    })
  }

  checkBet = (_betId: string, _betOutcomeId: string, _betOutcomeOdds: number) => {
    return (
      this.state.betId === _betId &&
      this.state.betOutcomeId === _betOutcomeId &&
      this.state.betOutcomeOdds === _betOutcomeOdds
    )
  }

  updateGame = () => {
    this.miniService.getMiniGameByGameType('pwball').then((s: any) => {
      if (s.status === 'success') {
        const now = s.now
        this.setState({ minis: s.minis[0] })

        if (s.minis.length > 0) {
          const gameDate = s.minis[0].gamedate
          if (this.state.gameDate !== gameDate) {
            const tick = new Date(gameDate).getTime() - new Date(now).getTime() - 40000
            this.setState({ seconds: tick, startImmediately: true })

            setTimeout(() => {
              // this.updateGame();
              this.updateMiniHistroy(1)
            }, tick + 105000)
          }
        }
      }
    })
  }

  handleGameMode = (_gameVIew: string) => {
    this.setState({ gameVIew: _gameVIew })
  }

  updateMiniHistroy = (page: any) => {
    this.miniService.getMiniGameBetList(page).then((s: any) => {
      if (s.status === 'success') {
        this.setState({ history: s.minis, maxCount: s.maxCount })
      }
    })
  }

  handleDobet = (episode: string) => {
    const balance = this.state.balance

    if (balance <= 0) {
      confirmAlert({
        title: '배팅',
        message: '배팅금액을 선택해주세요.',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })
      return
    }

    this.miniService
      .doBetMiniGame({
        betItem: [
          {
            categoryId: 'pwball',
            episode: episode,
            marketId: this.state.betId,
            betId: this.state.betOutcomeId,
            odds: this.state.betOutcomeOdds,
          },
        ],
        amount: this.state.balance,
      })
      .then((s: any) => {
        if (s.status === 'success') {
          confirmAlert({
            title: '배팅',
            message: '배팅이 정상적으로 처리되었습니다.',
            buttons: [
              {
                label: '확인',
                onClick: () => {
                  this.updateMiniHistroy(1)
                  this.setState({
                    balance: 0,
                    betId: '',
                  })
                },
              },
            ],
          })
        } else if (s.status === 'amount') {
          confirmAlert({
            title: '배팅',
            message: '보유금액이 초가하여 배팅할수가 없습니다.',
            buttons: [
              {
                label: '확인',
                onClick: () => {},
              },
            ],
          })
        } else if (s.status === 'betCount') {
          confirmAlert({
            title: '배팅',
            message: '배팅 제한을 확인해주세요.',
            buttons: [
              {
                label: '확인',
                onClick: () => {},
              },
            ],
          })
        } else if (s.status === 'cant_find') {
          confirmAlert({
            title: '배팅',
            message: '게임 선택후 배팅을 시도해주세요.',
            buttons: [
              {
                label: '확인',
                onClick: () => {},
              },
            ],
          })
        } else if (s.status === 'user') {
          confirmAlert({
            title: '배팅',
            message: '찾을수 없는 유저입니다.',
            buttons: [
              {
                label: '확인',
                onClick: () => {},
              },
            ],
          })
        } else if (s.status === 'betTime') {
          confirmAlert({
            title: '배팅',
            message: '배팅시간이 초가되었습니다.',
            buttons: [
              {
                label: '확인',
                onClick: () => {},
              },
            ],
          })
        } else if (s.status === 'betLimit') {
          confirmAlert({
            title: '배팅',
            message: '배팅 리밋을 확인해주세요.',
            buttons: [
              {
                label: '확인',
                onClick: () => {},
              },
            ],
          })
        } else {
          confirmAlert({
            title: '배팅',
            message: '관리자에게 문의 부탁드림니다.',
            buttons: [
              {
                label: '확인',
                onClick: () => {},
              },
            ],
          })
        }
      })
  }

  handleClose = (id: string) => {
    confirmAlert({
      title: '배팅 취소',
      message: '배팅 취소하시겠습니까? 취소된 배팅은 복구 할수 없습니다.',
      buttons: [
        {
          label: '확인',
          onClick: () => {
            this.miniService.miniCancelByUser(id).then((s: any) => {
              if (s.status === 'success') {
                confirmAlert({
                  title: '배팅',
                  message: '배팅 취소가 정상적으로 처리되었습니다.',
                  buttons: [
                    {
                      label: '확인',
                      onClick: () => {
                        this.updateMiniHistroy(1)
                        this.setState({
                          balance: 0,
                          betId: '',
                        })
                      },
                    },
                  ],
                })
              } else if (s.status === 'time') {
                confirmAlert({
                  title: '배팅',
                  message: '시간이 만료된 배팅입니다.',
                  buttons: [
                    {
                      label: '확인',
                      onClick: () => {},
                    },
                  ],
                })
              } else {
                confirmAlert({
                  title: '배팅',
                  message: '관리자에게 문의 부탁드림니다.',
                  buttons: [
                    {
                      label: '확인',
                      onClick: () => {},
                    },
                  ],
                })
              }
            })
          },
        },
        {
          label: '취소',
          onClick: () => {},
        },
      ],
    })
  }

  handleRolling = () => {
    confirmAlert({
      title: '롤링 금액',
      message: '롤링 금액을 전환하시겠습니까?',
      buttons: [
        {
          label: '확인',
          onClick: () => {
            this.userService.user_chang_to_rolling().then((s: any) => {
              if (s.status === 'success') {
                confirmAlert({
                  title: '롤링 금액',
                  message: '롤링 전환이 정상적으로  처리되었습니다.',
                  buttons: [
                    {
                      label: '확인',
                      onClick: () => {
                        this.updateMiniHistroy(1)
                        this.setState({
                          balance: 0,
                          betId: '',
                        })
                      },
                    },
                  ],
                })
              } else if (s.status === 'low_point') {
                confirmAlert({
                  title: '롤링 금액',
                  message: '1,000원 이상만 전환가능합니다.',
                  buttons: [
                    {
                      label: '확인',
                      onClick: () => {},
                    },
                  ],
                })
              } else {
                confirmAlert({
                  title: '배팅',
                  message: '관리자에게 문의 부탁드림니다.',
                  buttons: [
                    {
                      label: '확인',
                      onClick: () => {},
                    },
                  ],
                })
              }
            })
          },
        },
        {
          label: '취소',
          onClick: () => {},
        },
      ],
    })
  }

  render() {
    // if (this.state.minis == null) {
    //   return <></>;
    // }
    // let episode = this.state.minis.episode;
    const user = this.props.user
    // console.log(user)
    const episode = this.state.minis?.episode

    return (
      <div id="wrap">
        <div className="sub_w_Live" style={{ width: '1170px' }}>
          <div id="content" className="sub_cnt_Live games_wrap" style={{ width: '100%' }}>
            <div id="contentss" style={{ width: '100%' }}>
              <div style={{ position: 'relative' }}>
                <div id="result_box" className="resultBallWrap">
                  <div id="result_string"></div>
                  <div id="start_box"></div>
                </div>
              </div>
              <div className="div_left">
                <div className="row" id="powerballgame_iframe" style={{ marginBottom: '10px', marginLeft: '9px' }}>
                  <div className="col-md-12 col-sm-12 col-xs-12 ">
                    <div
                      id=""
                      style={{ width: '100%', height: '265px', overflow: 'hidden', position: 'relative' }}
                      className="contents-group2"
                    >
                      <div style={{ position: 'absolute', left: '-46px', top: '-9px' }}>
                        <iframe
                          id="powerballgame_results"
                          src="https://www.powerballgame.co.kr/?view=powerballMiniView"
                          scrolling="no"
                          width="900"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ position: 'relative', width: '100%', height: '40px', marginBottom: '5px' }}>
                  <div
                    className="div_header"
                    style={{
                      float: 'left',
                      marginLeft: '11px',
                      width: '369px',
                      height: '40px',
                      lineHeight: '40px',
                      fontSize: '20px',
                      background: 'rgb(255,229,229)',
                      textAlign: 'center',
                      color: 'red',
                    }}
                  >
                    파워볼
                  </div>
                  <div
                    className="div_header"
                    style={{
                      float: 'left',
                      marginLeft: '9px',
                      width: '369px',
                      height: '40px',
                      lineHeight: '40px',
                      fontSize: '20px',
                      background: 'rgb(255,229,229)',
                      textAlign: 'center',
                      color: 'Blue',
                    }}
                  >
                    일반볼
                  </div>
                </div>

                <div className="bet_btns2">
                  <div>
                    <ul>
                      {this.state.minis &&
                        Object.values(this.state.minis?.markets?.pw_oddoreven?.outcomes).map((s: any) => (
                          <li
                            className={
                              this.checkBet(this.state.minis?.markets?.pw_oddoreven?.id, s.id, s.odds) ? 'on' : ''
                            }
                          >
                            <a
                              onClick={() =>
                                this.selBet(this.state.minis?.markets?.pw_oddoreven?.id, s.id, s.odds, s.name)
                              }
                            >
                              <ul>
                                <li>
                                  <span className="even">{s.name}</span>{' '}
                                </li>
                                <li style={{ lineHeight: '20px' }}>
                                  <span className="rate" id="p_even_rate">
                                    {s.odds}
                                  </span>{' '}
                                </li>
                              </ul>
                            </a>
                          </li>
                        ))}
                      {this.state.minis &&
                        Object.values(this.state.minis?.markets?.pw_underorover?.outcomes).map((s: any) => (
                          <li className={this.checkBet(this.state.minis?.markets?.id, s.id, s.odds) ? 'on' : ''}>
                            <a
                              onClick={() =>
                                this.selBet(this.state.minis?.markets?.pw_underorover.id, s.id, s.odds, s.name)
                              }
                            >
                              <ul>
                                <li>
                                  <span className="under">{s.name}</span>{' '}
                                </li>
                                <li style={{ lineHeight: '20px' }}>
                                  <span className="rate" id="p_under_rate">
                                    {s.odds}
                                  </span>{' '}
                                </li>
                              </ul>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div>
                    <ul>
                      {this.state.minis &&
                        Object.values(this.state.minis?.markets?.pw_oddoreven_undeorrover?.outcomes).map((s: any) => (
                          <li
                            className={
                              this.checkBet(this.state.minis?.markets?.pw_oddoreven_undeorrover?.id, s.id, s.odds)
                                ? 'on'
                                : ''
                            }
                          >
                            <a
                              onClick={() =>
                                this.selBet(
                                  this.state.minis?.markets?.pw_oddoreven_undeorrover?.id,
                                  s.id,
                                  s.odds,
                                  s.name,
                                )
                              }
                            >
                              <ul>
                                <li>
                                  <span className="odd">{s.name}</span>{' '}
                                </li>
                                <li style={{ lineHeight: '20px' }}>
                                  <span className="rate" id="p_under_rate">
                                    {s.odds}
                                  </span>{' '}
                                </li>
                              </ul>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="bet_btns2" style={{ marginLeft: '11px' }}>
                  <div>
                    <ul>
                      {this.state.minis &&
                        Object.values(this.state.minis?.markets?.nor_oddoreven?.outcomes).map((s: any) => (
                          <li
                            className={
                              this.checkBet(this.state.minis?.markets?.nor_oddoreven?.id, s.id, s.odds) ? 'on' : ''
                            }
                          >
                            <a
                              onClick={() =>
                                this.selBet(this.state.minis?.markets?.nor_oddoreven?.id, s.id, s.odds, s.name)
                              }
                            >
                              <ul>
                                <li>
                                  <span className="even">{s.name}</span>{' '}
                                </li>
                                <li style={{ lineHeight: '20px' }}>
                                  <span className="rate" id="p_even_rate">
                                    {s.odds}
                                  </span>{' '}
                                </li>
                              </ul>
                            </a>
                          </li>
                        ))}
                      {this.state.minis &&
                        Object.values(this.state.minis?.markets?.nor_underorover?.outcomes).map((s: any) => (
                          <li
                            className={
                              this.checkBet(this.state.minis?.markets?.nor_underorover?.id, s.id, s.odds) ? 'on' : ''
                            }
                          >
                            <a
                              onClick={() =>
                                this.selBet(this.state.minis?.markets?.nor_underorover?.id, s.id, s.odds, s.name)
                              }
                            >
                              <ul>
                                <li>
                                  <span className="under">{s.name}</span>{' '}
                                </li>
                                <li style={{ lineHeight: '20px' }}>
                                  <span className="rate" id="p_even_rate">
                                    {s.odds}
                                  </span>{' '}
                                </li>
                              </ul>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div>
                    <ul>
                      {this.state.minis &&
                        Object.values(this.state.minis?.markets?.nor_oddoreven_undeorrover?.outcomes).map((s: any) => (
                          <li
                            className={
                              this.checkBet(this.state.minis?.markets?.nor_oddoreven_undeorrover?.id, s.id, s.odds)
                                ? 'on'
                                : ''
                            }
                          >
                            <a
                              onClick={() =>
                                this.selBet(
                                  this.state.minis?.markets?.nor_oddoreven_undeorrover?.id,
                                  s.id,
                                  s.odds,
                                  s.name,
                                )
                              }
                            >
                              <ul>
                                <li>
                                  <span className="odd">{s.name}</span>{' '}
                                </li>
                                <li style={{ lineHeight: '20px' }}>
                                  <span className="rate" id="p_even_rate">
                                    {s.odds}
                                  </span>{' '}
                                </li>
                              </ul>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="div_right">
                {user && (
                  <div id="current_money_info">
                    <ul className="second">
                      <li className="header">보유금액 </li>
                      <li className="money_info">
                        <span>
                          <span id="CurrentUserMoney" style={{ marginLeft: '0px' }}>
                            {ConverMoeny(user.balance)}
                          </span>
                        </span>
                      </li>
                    </ul>
                    <ul className="second">
                      <li className="header">마일리지</li>
                      <li className="money_info">
                        <span>
                          <span id="CurrentUserPoint">{ConverMoeny(user.point)}</span>
                          {/* <input className="btn_bet" type="button" value="구매하기" /> */}
                          <a
                            onClick={() => this.handleRolling()}
                            className="toggleBtn"
                            id="showpwb"
                            style={{ background: 'rgb(239,159,34)', color: '#000' }}
                          >
                            전환
                          </a>
                          {/*<button type="button" className="btn_bet" value="전환">전환</button> */}
                        </span>
                      </li>
                    </ul>
                    <ul className="second">
                      <li className="header">구매가능 </li>
                      <li className="money_info">
                        <span id="possible_betmoney">{ConverMoeny(this.state.balance)}</span>
                      </li>
                    </ul>
                    <ul className="second">
                      <li className="header">예상적중</li>
                      <li className="money_info">
                        <span id="winPrice">{ConverMoeny(this.state.balance * this.state.betOutcomeOdds)}</span>
                      </li>
                    </ul>
                    <ul className="second">
                      <li className="header">구매금액 </li>
                      <li className="money_info">
                        <input
                          name="price"
                          id="price"
                          style={{ color: 'Red', border: 0, textAlign: 'right' }}
                          value={ConverMoeny(this.state.balance)}
                          readOnly
                        />
                      </li>
                    </ul>
                  </div>
                )}

                <div id="select_betmoney">
                  <table style={{ float: 'right', marginRight: '3px', width: '380px' }} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td>
                          <div className="powerball_wrap">
                            <div id="game_info_div">
                              <div className="game_info">
                                <span>{episode}</span>
                                {/* <em><span id="date_hh">03</span>:<span id="date_ii">25</span>:<span id="date_ss">55</span></em> */}
                                {/* <span className="order">(<span id="fullRound">1114200</span>)</span> <span className="daily_episode" id="currRound">0</span>회차 */}
                              </div>
                            </div>
                            <div className="powerball_cnt">
                              <div className="powerball_cart">
                                {Number(episode?.split('-')[1]) < 72 ? (
                                  <div
                                    id="betting_disable"
                                    className="bet_disable"
                                    style={{ display: 'block', fontSize: '32px' }}
                                  >
                                    서버점검중입니다.
                                  </div>
                                ) : (
                                  <div className="cart_pay">
                                    <div className="bet_btn_inner">
                                      <input
                                        type="button"
                                        value="1만"
                                        style={{
                                          backgroundImage:
                                            'linear-gradient(rgb(255, 248, 255) 0%, rgb(198, 253, 179) 100%) !important',
                                        }}
                                        onClick={() => this.setState({ balance: this.state.balance + 10000 })}
                                      />
                                      <input
                                        type="button"
                                        value="3만"
                                        style={{
                                          backgroundImage:
                                            'linear-gradient(rgb(255, 248, 255) 0%, rgb(198, 253, 179) 100%) !important',
                                        }}
                                        onClick={() => this.setState({ balance: this.state.balance + 30000 })}
                                      />
                                      <input
                                        type="button"
                                        value="5만"
                                        style={{
                                          backgroundImage:
                                            'linear-gradient(rgb(255, 248, 255) 0%, rgb(198, 253, 179) 100%) !important',
                                        }}
                                        onClick={() => this.setState({ balance: this.state.balance + 50000 })}
                                      />
                                      <input
                                        type="button"
                                        value="10만"
                                        style={{
                                          backgroundImage:
                                            'linear-gradient(rgb(255, 248, 255) 0%, rgb(198, 253, 179) 100%) !important',
                                        }}
                                        onClick={() => this.setState({ balance: this.state.balance + 100000 })}
                                      />

                                      <input
                                        type="button"
                                        value="30만"
                                        style={{
                                          backgroundImage:
                                            'linear-gradient(rgb(255, 248, 255) 0%, rgb(71, 250, 12) 100%) !important;',
                                        }}
                                        onClick={() => this.setState({ balance: this.state.balance + 300000 })}
                                      />
                                      <input
                                        type="button"
                                        value="50만"
                                        style={{
                                          backgroundImage:
                                            'linear-gradient(rgb(255, 248, 255) 0%, rgb(71, 250, 12) 100%) !important;',
                                        }}
                                        onClick={() => this.setState({ balance: this.state.balance + 500000 })}
                                      />
                                      <input
                                        type="button"
                                        value="100만"
                                        style={{
                                          backgroundImage:
                                            'linear-gradient(rgb(255, 248, 255) 0%, rgb(71, 250, 12) 100%) !important;',
                                        }}
                                        onClick={() => this.setState({ balance: this.state.balance + 1000000 })}
                                      />
                                      <input
                                        className="i_ivory"
                                        type="button"
                                        value="초기화"
                                        onClick={() => this.setState({ balance: 0 })}
                                      />
                                    </div>
                                    <div style={{ float: 'left' }}>
                                      <span className="count" id="timeLimit">
                                        <Timer
                                          initialTime={this.state.seconds}
                                          startImmediately={false}
                                          lastUnit="h"
                                          direction="backward"
                                          formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
                                          checkpoints={[
                                            {
                                              time: 1000,
                                              callback: () => {
                                                setTimeout(() => {
                                                  this.updateGame()
                                                  this.updateMiniHistroy(1)
                                                }, 45000)

                                                setTimeout(() => {
                                                  this.updateGame()
                                                  this.updateMiniHistroy(1)
                                                }, 35000)
                                              },
                                            },
                                          ]}
                                        >
                                          {(param: any) => {
                                            const fun = { ...param }

                                            if (this.state.startImmediately === true) {
                                              this.setState({ startImmediately: false })
                                              fun.setTime(this.state.seconds)
                                              fun.start()
                                            }

                                            return (
                                              <span style={{ fontSize: '20px' }}>
                                                {'('}
                                                <Timer.Minutes />:
                                                <Timer.Seconds />
                                                {')'}
                                              </span>
                                            )
                                          }}
                                        </Timer>
                                      </span>
                                    </div>
                                    <div style={{ float: 'right' }}>
                                      <input
                                        className="btn_bet"
                                        type="button"
                                        value="구매하기"
                                        onClick={() => this.handleDobet(episode)}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="bet_btn_last_betinfo" id="bet_btn_last_betinfo">
                  <div id="loading" style={{ display: 'none' }}>
                    <div className="loading"></div>
                  </div>
                  <table id="bettingToday" style={{ width: '95%', margin: '0px auto' }}>
                    <colgroup>
                      <col width="80px" />
                      <col width="80px" />
                      <col width="*" />
                      <col width="60px" />
                      <col width="50px" />
                    </colgroup>
                    <tbody>
                      {Object.values(this.state.history).map((ss: any) => (
                        <tr>
                          <td>{ss.episode}</td>
                          <td>{ss.betName}</td>
                          <td>{ConverMoeny(ss.bet)}</td>
                          <td>{ConverMoeny(ss.win)}</td>
                          <td>
                            {ss.betStatus}
                            {ss.betStatus === 'bet' && (
                              <img
                                src="/web/images/sclose.png"
                                width="16"
                                onClick={() => {
                                  this.handleClose(ss._id)
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{ position: 'relative' }}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

power.propTypes = {
  classes: PropTypes.object.isRequired,
}

export const Power = withStyles({}, { withTheme: true })(power)
export default Power
