import Moment from 'moment'

export enum popupView {
  none = 'none',
  deposit = 'deposit',
  withdraw = 'withdraw',
  bet = 'bet',
  point = 'point',
  notice = 'notice',
  note = 'note',
  help = 'help',
  user = 'user',
  comp = 'comp',

  reg = 'reg',
  login = 'login',
}

function pad(number: any, length: any) {
  let str = `${number}`
  while (str.length < length) {
    str = `0${str}`
  }

  return str
}
export const ConvertTime = (date: any): any => {
  const today = new Date(date)

  const hh = pad(today.getHours(), 2)
  const mm = pad(today.getMinutes(), 2)
  return `${hh}:${mm}`
}

export const ConverStatus = (status: string): any => {
  if (status === 'win') {
    return '실현'
  } else if (status === 'lose') {
    return '실격'
  } else if (status === 'reg') {
    return '대기'
  }

  return '-'
}

export const ConvertBalanceStateToText = (status: any) => {
  if (status === 'wait') return '대기'
  if (status === 'reg') return '대기'
  if (status === 'already') return '완료'
  if (status === 'cansel') return '취소'
  return '-'
}
export const ConverBuySell = (status: string): any => {
  if (status === 'high') {
    return '매수'
  } else if (status === 'low') {
    return '매도'
  }

  return '-'
}

export const HelpStatus = (status: any) => {
  if (status === 'wait') return '대기'
  if (status === 'reg') return '대기'
  if (status === 'already') return '완료'
  if (status === 'cansel') return '취소'

  return status
}

export const ConverMoeny = (balance: number): any => {
  return Number(balance).toLocaleString()
}

export const ConvertDate = (dt: any) => {
  return Moment(dt).format('MM-DD HH:mm')
}

export const ConvertDate2 = (dt: any) => {
  return Moment(dt).format('MM-DD HH:mm:ss')
}

export const ConvertDateDay = (dt: any) => {
  return Moment(dt).format('MM-DD')
}

export const GetTimeStemp = () => {
  return new Date().getTime()
}

export const Pad = (number: number, length: number) => {
  let str = `${number}`
  while (str.length < length) {
    str = `0${str}`
  }

  return str
}

export const GetToday = (): any => {
  const aa = new Date()
  const today = new Date(aa.setHours(0, 0, 0, 0))

  const yyyy = today.getFullYear().toString()
  const MM = Pad(today.getMonth() + 1, 2)
  const dd = Pad(today.getDate(), 2)

  return `${yyyy}-${MM}-${dd}`
}

export const ConverDateFull = (date: any): any => {
  const today = new Date(date)

  const yyyy = today.getFullYear().toString()
  const MM = Pad(today.getMonth() + 1, 2)
  const dd = Pad(today.getDate(), 2)
  const hh = Pad(today.getHours(), 2)
  const mm = Pad(today.getMinutes(), 2)
  const ss = Pad(today.getSeconds(), 2)

  return `${yyyy}-${MM}-${dd} ${hh}:${mm}`
}
