import React, { Component } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import Popup from 'reactjs-popup'
import CloseIcon from '@mui/icons-material/Close'
import { confirmAlert } from 'react-confirm-alert' // Import
import { UserService } from '../../service/user.service'
import { SlotService } from '../../service/slot.service'

import { ShareMenu } from './share_menu'
import { isMaster } from '../../App'

export enum Mode {
  none = 'none',
  game = 'game',
  slot = 'slot',
}

interface Props {
  gamename: string
  handleClose: () => any
  handleChange: (viewName: string) => any
}

interface State {
  mode: string
  slots: any
  games: any
  withdraws: any
  deposits: any
  casinos: any
  isOpen: boolean
}

export class Companylist extends Component<Props, State> {
  slotService = new SlotService()
  constructor(props: Props) {
    super(props)

    this.state = {
      mode: Mode.none,
      slots: [],
      games: [],
      withdraws: [],
      deposits: [],
      casinos: [],
      isOpen: false,
    }
  }

  componentDidMount() {
    this.slotService.get_slot_by_company(this.props.gamename).then((s: any) => {
      if (s.status === 'success') {
        this.setState({ games: s.data, mode: Mode.game })
      }
    })
  }

  handleOpenSlot = (vendor: string, code: string) => {
    console.log('openSlot : ' + vendor + ' ' + code)
    this.slotService.OpenSlot(vendor, code).then((data: any) => {
      console.log('openSlot result : ' + JSON.stringify(data))

      this.setState({ isOpen: false })

      if (data.status === 'success') {
        const timn = new Date().getTime()
        window.open(data.data.gameUrl, 'Data', 'height=800,width=1400')
      }
    })
  }

  RenderGame = (info: any) => {
    return (
      <a href="javascript:void(0);" className="game-btn" key={info.code}>
        <div className="btn-container">
          <img className="main-img lazyLoad" src={`${info.imgUrl}`} data-src={`${info.imgUrl}`} />
          <button
            className="play-btn"
            onClick={() => {
              this.handleOpenSlot(info.vendor, info.code)
            }}
          >
            <i className="fas fa-play ml-1" aria-hidden="true"></i>
          </button>
          <div className="footer">
            <span className="name">{info.nameKo}</span>
          </div>
        </div>
      </a>
    )
  }

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: 'none',
          border: 'none',
          width: 'none',
        }}
        overlayStyle={{
          overflow: 'scroll',
        }}
      >
        {(close) => (
          <div>
            <div className="modal-app show">
              <button
                className="modal-close-btn modal-close"
                data-dismiss="modal"
                onClick={() => {
                  this.props.handleClose()
                }}
              ></button>
              <a href="/" className="diamond-logo">
                <img className="panel" src="/new/images/panel.png" />
                <img className="dias-lg" src="/new/images/diamond-lg.png" />
                <div className="dias-sm">
                  <img className="diamond-img" src="/new/images/diamond-sm.png" />
                  <img className="glow" src="/new/images/glow.png" />
                </div>
                <img className="diamond" src="/new/images/diamond.png" />
                <img className="slot" src="/new/images/slot.png" />
              </a>

              <ShareMenu handleChange={this.props.handleChange}></ShareMenu>
            </div>

            <div
              className="modal loginModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              aria-modal="true"
              style={{ paddingRight: '17px', display: 'block' }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="row">
                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pr-lg-5 px-3">
					<div className="modal-title">
					   <h6 className="title mb-0">메뉴</h6>
					   <h6 className="sub ml-2 mb-0">MENU</h6>
					</div>
					<div className="modal-menu">
					   <button className="active">
						  <i className="fas fa-sign-in-alt icon" aria-hidden="true"></i>
						  <span>전체</span>
						  <div className="indicator">
							 <i className="fab fa-ethereum" aria-hidden="true"></i>
						  </div>
					   </button>
					</div>
				 </div> */}
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                      <div className="modal-body">
                        <div className="modal-title">
                          <h6 className="title mb-0">게임리스트</h6>
                          <h6 className="sub ml-2 mb-0">GAME LIST</h6>
                        </div>

                        <div className="game-list-container">
                          <div className="scroll-container">
                            {this.state.games.map((row: any) => this.RenderGame(row))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-backdrop fade show"></div>
          </div>
        )}
      </Popup>
    )
  }
}
