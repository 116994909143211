import React, { Component } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import Popup from 'reactjs-popup'
import CloseIcon from '@mui/icons-material/Close'
import { confirmAlert } from 'react-confirm-alert' // Import

import { BalanceService } from '../../service/balance.service'

import { ConverMoeny, popupView } from '../../utility/help'

interface Props {
  handleChange: (viewName: string) => any
}

interface State {
  balance: number
  point: number
}

export class ShareMenu extends Component<Props, State> {
  render() {
    return (
      <div className="modal-menu-side">
        <button
          className="deposit-link"
          onClick={() => {
            this.props.handleChange(popupView.deposit)
          }}
        >
          <i className="fas fa-coins icon" aria-hidden="true"></i>
          <span className="text">입금신청</span>
        </button>
        <button
          className="withdraw-link"
          onClick={() => {
            this.props.handleChange(popupView.withdraw)
          }}
        >
          <i className="fas fa-university icon" aria-hidden="true"></i>
          <span className="text">출금신청</span>
        </button>
        <button
          className="event-link"
          onClick={() => {
            this.props.handleChange(popupView.point)
          }}
        >
          <i className="fas fa-gem icon" aria-hidden="true"></i>
          <span className="text">금고</span>
        </button>
        <button
          className="event-link"
          onClick={() => {
            this.props.handleChange(popupView.comp)
          }}
        >
          <i className="fas fa-gem icon" aria-hidden="true"></i>
          <span className="text">콤프</span>
        </button>
        <button
          className="notice-link"
          onClick={() => {
            this.props.handleChange(popupView.help)
          }}
        >
          <i className="fas fa-info icon" aria-hidden="true"></i>
          <span className="text">고객센터</span>
        </button>
        <button
          className="myPage-link"
          onClick={() => {
            this.props.handleChange(popupView.user)
          }}
        >
          <i className="fas fa-user icon" aria-hidden="true"></i>
          <span className="text">마이페이지</span>
        </button>
        <button
          className="notice-link"
          onClick={() => {
            this.props.handleChange(popupView.notice)
          }}
        >
          <i className="fas fa-crown icon" aria-hidden="true"></i>
          <span className="text">공지사항</span>
        </button>
      </div>
    )
  }
}
