import React, { Component } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import Popup from 'reactjs-popup'
import CloseIcon from '@mui/icons-material/Close'
import { confirmAlert } from 'react-confirm-alert' // Import

import { UserService } from '../../service/user.service'
import { ShareMenu } from './share_menu'
import { ShareLeft } from './share_left'

import { popupView } from '../../utility/help'

interface Props {
  handleClose: () => any
  handleChange: (viewName: string) => any
}

interface State {
  pass1: string
  pass2: string
}

export class User extends Component<Props, State> {
  userService = new UserService()
  constructor(props: Props) {
    super(props)

    this.state = {
      pass1: '',
      pass2: '',
    }
  }

  handleExchangeToPassword = () => {
    if (this.state.pass1.length < 3 || this.state.pass1.length > 20) {
      confirmAlert({
        title: '회원정보',
        message: '비밀번호 입력 또는 3자리 이상 20자리 이하로 작성해주세요',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })
      return
    }

    if (this.state.pass1 !== this.state.pass2) {
      confirmAlert({
        title: '회원정보',
        message: '비밀번호정보가 동일해야합니다 ',
        buttons: [
          {
            label: '확인',
            onClick: () => {},
          },
        ],
      })
      return
    }

    this.userService.user_exchange_to_pass(this.state.pass1).then((s: any) => {
      if (s.status === 'success') {
        confirmAlert({
          title: '회원정보',
          message: '비밀번호정보가 정상적으로 변경되었습니다. ',
          buttons: [
            {
              label: '확인',
              onClick: () => {
                this.props.handleClose()
              },
            },
          ],
        })
        return
      } else {
        confirmAlert({
          title: '회원정보',
          message: '알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ',
          buttons: [
            {
              label: '확인',
              onClick: () => {},
            },
          ],
        })
        return
      }
    })
  }

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: 'none',
          border: 'none',
          width: 'none',
        }}
        overlayStyle={{
          overflow: 'scroll',
        }}
      >
        {(close) => (
          <div>
            <div className="modal-app show">
              <button
                className="modal-close-btn modal-close"
                data-dismiss="modal"
                onClick={() => {
                  this.props.handleClose()
                }}
              ></button>
              <a href="/" className="diamond-logo">
                <img className="panel" src="/new/images/panel.png" />
                <img className="dias-lg" src="/new/images/diamond-lg.png" />
                <div className="dias-sm">
                  <img className="diamond-img" src="/new/images/diamond-sm.png" />
                  <img className="glow" src="/new/images/glow.png" />
                </div>
                <img className="diamond" src="/new/images/diamond.png" />
                <img className="slot" src="/new/images/slot.png" />
              </a>

              <ShareMenu handleChange={this.props.handleChange}></ShareMenu>
            </div>

            <div
              className="modal loginModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              aria-modal="true"
              style={{ paddingRight: '17px', display: 'block' }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="row">
                    <ShareLeft handleChange={this.props.handleChange} viewType={popupView.user}></ShareLeft>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-left">
                      <div className="modal-body">
                        <div className="modal-title">
                          <h6 className="title mb-0">마이페이지</h6>
                          <h6 className="sub ml-2 mb-0">MY PAGE</h6>
                        </div>

                        <div className="modal-information mb-md-5 mb-4">
                          <span>* 로그인 비밀번호를 변경합니다.</span>
                        </div>

                        <div className="form-container">
                          <div className="form-group">
                            <div className="labels">
                              <span>변경 비밀번호</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="password"
                                  className="form-control parsley-success"
                                  value={this.state.pass1}
                                  onChange={(e: any) => {
                                    this.setState({ pass1: e.target.value })
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="labels">
                              <span>확인 비밀번호</span>
                            </div>
                            <div className="input-container">
                              <div className="infos">
                                <input
                                  type="password"
                                  className="form-control parsley-success"
                                  value={this.state.pass2}
                                  onChange={(e: any) => {
                                    this.setState({ pass2: e.target.value })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="modal-footer">
                          <button type="submit" className="btn-style" onClick={this.handleExchangeToPassword}>
                            비밀번호 변경
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-backdrop fade show"></div>
          </div>
        )}
      </Popup>
    )
  }
}
