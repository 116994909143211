import React, { Component } from 'react'

import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { withStyles } from '@mui/styles'
import styled from 'styled-components'
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'

import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'

// import Iframe from "react-iframe";
import { MiniService } from '../../service/mini.service'

import { Header } from '../share_new/header'
import { Section } from '../share_new/section'
import { Company } from '../share_new/company'
import { Footer } from '../share_new/footer'

// import { ConverStatus, ConverBuySell, ConverMoeny } from "../utility/help";

const drawerWidth = 240

const styles = (theme: any) => ({
  root: {
    display: 'flex',
  },
  paper: {
    paddingTop: 110,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    color: '#000',
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
    display: 'inline',
    padding: '10px',
    fontSize: '16px',
  },
  investing: {
    fontSize: '18px',
  },
})

const CustomCardContent = styled(CardContent)`
  padding: 0px;
`

const CustomBetButton = styled(Button)`
  width: 24%;
  font-size: 10px;

  box-shadow: inset 0px 1px 0px 0px #f9eca0;
  background: linear-gradient(to bottom, #f0c911 5%, #f2ab1e 100%);
  background-color: #f0c911;
  border-radius: 6px;
  border: 1px solid #e65f44;
  display: inline-block;
  cursor: pointer;
  color: #c92200;
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ded17c;
`

const CustomList = styled(List)`
  box-shadow: inset 0px 1px 0px 0px #cf866c;
  background: linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
  background-color: #d0451b;
  border-radius: 3px;
  border: 1px solid #942911;
  color: #ffffff;
  font-family: Arial;
  font-size: 12px;
  margin-top: 12px;
`

const CustomTableCell = styled(TableCell)`
  color: white;
  width: 24%;
  font-size: 10px;
  padding: 5px;
`

// const CustomCardContent = styled(Typography)``;

// const classes = useStyles();

interface Props {
  classes: any
  authenticated: boolean
  activeHelp: boolean

  session: any
  user: any

  tryLogin: (id: any, pw: any) => any
  tryLoginOut: () => any
}

class game extends Component<Props, object> {
  divElement: any
  static propTypes: { classes: PropTypes.Validator<object> }
  // fxService = new FxService();
  // userService = new UserService();
  miniService = new MiniService()

  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    if (this.divElement != null) {
      this.setState({})
    }
  }

  createData = (name: any, balnce: any, fat: any, maxBalanceCount: any, carbs: any, protein: any) => {
    return {
      name,
      balnce,
      fat,
      maxBalanceCount,
      buyCount: 0,
      sellCount: 0,
      carbs,
      protein,
    }
  }

  updateUserDate = () => {}

  updateMiniHistroy = (page: any) => {
    this.miniService.getMiniGameBetList(page).then((s: any) => {
      if (s.status === 'success') {
        this.setState({ history: s.minis })
      }
    })
  }

  updateNowDate = (currency: string, minute: number) => {}

  updateGameDate = (currency: string, minute: number) => {}

  handleDobet = (episode: string) => {}

  render() {
    const classes = this.props.classes

    return (
      <div>
        <Header
          activeHelp={this.props.activeHelp}
          tryLogin={this.props.tryLogin}
          tryLoginOut={this.props.tryLoginOut}
          user={this.props.user}
          authenticated={this.props.authenticated}
        ></Header>

        <Section
          activeHelp={this.props.activeHelp}
          tryLogin={this.props.tryLogin}
          tryLoginOut={this.props.tryLoginOut}
          user={this.props.user}
          authenticated={this.props.authenticated}
        ></Section>

        {
          <Company
            activeHelp={this.props.activeHelp}
            tryLogin={this.props.tryLogin}
            tryLoginOut={this.props.tryLoginOut}
            user={this.props.user}
            authenticated={this.props.authenticated}
          ></Company>
        }
        {<Footer></Footer>}
      </div>
    )
  }
}

game.propTypes = {
  classes: PropTypes.object.isRequired,
}

export const Game = withStyles(styles, { withTheme: true })(game)
export default Game

// export default withStyles(styles)(Game);
///C:\WORK\pw-yun\src\web\main\pw.tsx
// export default withStyles(useStyles)(Game);
