import axios from 'axios'

import { AxiosService } from './axios.service'

export class SlotService extends AxiosService {
  constructor() {
    super() // exception thrown here when not called
  }

  async getSlotSetting() {
    //return { status: 'false', slot: {} }
    return this.SendMessageByPost('/public/slot/get_slot_setting', {})
    //return axios.post('/public/slot/get_slot_setting').then((res) => res.data.data)
  }

  async getCasinoSetting() {
    /*return {
      status: 'success',
      data: [
        {
          _id: '6265e41a4e73d46bba47f66b',
          master: 'aae',
          company: 'europa',
          nameEn: 'Evolution',
          nameKo: '에볼류션',
          code: 'plevc',
          mobileImg: '/img/casino/Evolution.png',
          used: 'y',
          order: 1,
          gameCode: 'plevc',
        },
      ],
    }*/
    return this.SendMessageByPost('/public/slot/get_casino_setting', {})
  }

  get_slot_by_company(company: any) {
    console.log('get_slot_by_company : ' + company)
    return this.SendMessageByPost('/public/slot/get_slot_by_company', {
      company: company,
    }).then((res) => res)
  }

  ///
  get_slot_bets_list(params: any) {
    return this.SendMessageByPost('/slot/get_slot_bets_list', params).then((res) => res)
  }

  OpenSlot(vendor: string, code: string) {
    return this.SendMessageByPost('/public/slot/user_open_game', {
      code,
      vendor,
    })
  }

  OpenCasino(vendor: string, code: string) {
    return this.SendMessageByPost('/public/slot/user_open_game', {
      code,
      vendor,
    })
  }

  async get_in_game_balance() {
    //return 0
    return this.SendMessageByPost('/public/slot/get_in_game_balance', {})
  }

  TransferIn(balance: string) {
    return axios.post('/casino/user_transfer_in', { balance: balance }).then((res) => res.data.data)
  }

  // TransferOut(balance: string) {
  //   return axios
  //     .post("/casino/user_transfer_out", { balance: balance })
  //     .then((res) => res.data.data);
  // }

  withdraw(balance: string) {
    return axios.post('/public/balance/apply_user_withdraw', { balance: balance }).then((res) => res.data.data)
  }
}
